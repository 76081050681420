// ステージング用の環境設定
export const environment = {

	production: true,

	API_HOST_URL: 'https://staging.api.wellnest-clinic.com',

  ///////////////////////////////////////////////////////////
	// 開発用
	// ★必要があれば、再利用でなく専用のフラグを作る事
	DEBUG_MODE_AUTHTRUE 			    : false, // 認証をスルーする
	DEBUG_MODE_ADMIN    			    : false, // ユーザーの種類がAdmin
	DEBUG_MODE_DUMYDATA 			    : false, // DAOでサーバから取らず、ダミーデータを返す。
	DEBUG_MODE_USER_ANSWER_NOSAVE 		: false, // DAAOでユーザの回答結果をサーバー送信をしない。

	DUMMY_APP_TOKEN: '', //　認証スルー時に設定されるダミートークン



	///////////////////////////////////////////////////////////
	// 各種設定

	// アプリのバージョンストリング
	APP_VESION_STRING : require('../../package.json').version+'-rel',

	// HTTP Requestのリトライ回数
	HTTP_REQUEST_RETRY : 2,

	// ローカルストレージ保存用キー
	LOCAL_STORAGE_KEY_TOKEN : 'gtk', // アプリトークン

};
